.marker-icon {
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  background: #0080FF;
  /* FDS Blue 100 (#C2E0FF) */
  border: 1px solid #C2E0FF;
  box-shadow: 0px 0px 10px rgba(0, 128, 255, 0.6);
  border-radius:50%;
}

.marker-name {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 12px;

  height: 36px;
  /* left: 89px;
  top: 721px; */

  background: #FFFFFF;
  border: 1px solid #0080FF;
  border-radius: 100px;
}

.marker-name-box {
  max-width: 169px;
  height: 14px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #2A2A2A;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marker-tooltip-name {
  height: 12px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.hide-marker {
  visibility: hidden;
}